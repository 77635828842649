.btn_trash{
    background: no-repeat;
    border: 0;
    font-size: 20px;
}
.txtdis{
    width: 78px;
    float: right;
    height: 35px;
}
body{
    background-color: lightskyblue;
}
.card-body{
    cursor: pointer;
}
.footercss{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background: lightskyblue;
    padding: 1.5rem 0;
}
.bg_headercss{
   background-color: #87cefa;
}
.navbar-vertical .navbar-nav .nav-link.active:before{
    border-left: 2px solid #87cefa;
}
.page-item.active .page-link {
    background-color: #87cefa;
    border-color: #87cefa; }

    .navbar-vertical .navbar-nav .nav-link{
        font-size: 17px;
    }
.new_line {
  white-space: pre-line;
}
.login_title{
    font-size: 22px;
    font-weight: bold;
    display: block;
}
.login_card{
    padding-top: 5%;
    padding-bottom: 0%;
    border-bottom: 0;
}
.input-group-text{
    background-color: #e8f0fe !important;
}
.qtytxt{
    width : 100%;
    height : calc(1.75rem + 2px);
}
.distxt{
    display : inline-block;
    width : 70%;
    height : calc(1.75rem + 2px);
}
.product_hr{
    margin-top: 5px;
    margin-bottom: 10px;
}
.amt_div{
    border-left: 1px solid #eee;
}
.del_section{
    display: flex;
}
.product_lbl{
    color: #525f7f;
    font-size: 0.875rem;
    font-weight: 600;
}
.stock_card_body{
    padding: 0.7rem 0;
}
.stock_card_data{
    font-weight: 600;
    padding: 0 0.8rem;
}
.stock_card_divider{
    margin: 3% 0;
    border-color: lightskyblue;
}
.stock_card_metadata{
    color: #f2365c;
}
.stock_card_label{
float:right;
font-size: 0.8em;
}
.stock_header{
color: #f2365c;
}
.dash_card{
    padding : 9px 7px !important;
}
.logout_icon{
    font-size: 23px;
    font-weight: 800;
}
.order_card{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    text-align: center;
}
.order_card_title{
    font-size: 14px;
}
.right_content{
    float: right;
}
@media only screen and (max-width: 586px) and (min-width: 320px){
    .qty_col{
        flex: 0 0 37.66667%;
        max-width: 37.66667%;
        font-size: 14px;
    }
    .amt_col{
        flex: 0 0 62.33333%;
        max-width: 62.33333%;
        font-size: 14px;
        padding-left: 0;
    }
    .tot_section{
        font-size: 14px;
    }
    .order_card_title{
        font-size: 11px;
    }
    .customer_name{
        font-size: 14px;
    }
    .stock_card_label{
        font-size: 11px;
    }
    .stock_card_data{
        font-size: 11.5px;
    }
}
@media only screen and (max-width:667px) and (min-width:375px){
    .order_card_title{
        font-size: 13.7px;
    }
    .customer_name{
        font-size: 16px;
    }
    .stock_card_data{
        font-size: 14px;
    }
}
@media only screen and (max-width:736px) and (min-width:414px) {
    .order_card_title{
        font-size: 15px;
    }
    .customer_name{
        font-size: 17px;
    }
    .stock_card_data{
        font-size: 14px;
    }
}
.pagination {
    display: inherit;
}  
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    background-color: rgba(255, 255, 255, 0.815);
    border: 1px solid #5e72e4;
    margin: 0 2px;
  }
  .pagination li.active a{
    background-color: #5e72e4;
    color: white;
    border: 1px solid #5e72e4;
  }
.pagination a:hover:not(.active) {background-color: #ddd;}
.pagination a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}  
.pagination a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.my-badge{
    float: right;
}